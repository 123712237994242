import hypolite from "../assets/images/hypolite.jpg";
import marina from "../assets/images/marina.jpg";

export const Invervenant = () => {
  return (
    <div className={"text-center lg:py-20"}>
      <h1 className={"text-4xl lg:text-7xl pb-5 font-lovelo"}>
        Les <br /> intervenant.es
      </h1>
      <div className={"flex flex-col lg:flex-row gap-8 text-left pt-8 pb-24"}>
        <img
          className={"w-56 h-96 object-cover mx-auto"}
          src={hypolite}
          alt={
            "Un homme assis sur un estrade, dans une lumière bleutée tendant le regard et le bras\n" +
            "vers la droite, avec un carnet sur les genoux, un stylo dans la main et une canette posée\n" +
            "sur sa droite."
          }
        />
        <p
          className={"text-lg text-justify lg:text-left lg:text-2xl leading-9"}
        >
          Hypolite est un comédien, danseur, auteur et metteur en scène lyonnais
          formé à l’école d’Arts en scène. C’est en troisième année qu’il met en
          scène pour la première fois un de ces textes. L'importance d'allier le
          corps du comédien à sa voix lui apparaît comme une évidence, il
          travaille sur des propositions scéniques des comédiens pour affiner et
          donner de la profondeur à son texte.
        </p>
        <img
          className={"w-56 h-96 object-cover mx-auto"}
          src={marina}
          alt={
            "Photo en noir et blanc d’une femme aux cheuveux attachés, portant un haut noir, debout\n" +
            "derrière une table sur laquelle est posée une boite en carton et une poupée marionette."
          }
        />
        <p
          className={"text-lg text-justify lg:text-left lg:text-2xl leading-9"}
        >
          Depuis 15 ans, Marina excelle en tant que metteuse en scène,
          comédienne, chanteuse et intervenante théâtre. Elle a enrichi son
          parcours en obtenant une certification d'art-thérapeute contemporaine
          à l’institut PROFAC. Formée initialement au théâtre du Gai Savoir,
          elle ne cesse de se perfectionner dans tous les domaines artistiques
          en poursuivant sa formation, considérant la transmission comme une
          valeur essentielle.
        </p>
      </div>
    </div>
  );
};
