import logo from "../assets/images/logo.png";

export const Logo = () => {
  return (
    <div className={"logo flex justify-center"}>
      <img
        className={"w-auto h-40 lg:w-1/6 lg:h-1/6"}
        src={logo}
        alt={
          'Un cercle noir arborant l\'inscription "Scène éclat" manuscrite, où la première lettre "E" de\n' +
          '"Scène" est la même que celle du mot "éclat". En bas à gauche, un réverbère mural est\n' +
          "représenté avec quatre lignes pour indiquer qu'il est allumé."
        }
      ></img>
    </div>
  );
};
