import "./assets/styles/app.scss";
import { Navbar } from "./components/Navbar";
import "./assets/fonts/TT Drugs Trial Regular.otf";
import { Formation } from "./pages/Formation";
import { Presentation } from "./pages/Presentation";
import { Invervenant } from "./pages/Invervenant";
import { Contenu } from "./pages/Contenu";
import { Inscription } from "./pages/Inscription";
import { Lieux } from "./pages/Lieux";
import { Tarif } from "./pages/Tarif";

const links = [
  {
    title: "la formation",
    slug: "formation",
    componentsParent: <Formation />,
    componentsChild: <Presentation />,
  },
  {
    title: "le contenu",
    slug: "contenu",
    componentsChild: <Invervenant />,
    componentsParent: <Contenu />,
  },
  {
    title: "inscription",
    slug: "inscription",
    componentsParent: <Inscription />,
  },
  { title: "les lieux", slug: "lieux", componentsParent: <Lieux /> },
  {
    title: "tarifs et contacts",
    slug: "tarifs",
    componentsParent: <Tarif />,
  },
];

export const App = () => {
  return (
    <>
      <title>Scene Eclat</title>
      <div className={"app px-20"}>
        <Navbar links={links} />
        {links.map((link) => (
          <>
            {link.componentsChild}
            <div id={link.slug}>{link.componentsParent}</div>
          </>
        ))}
      </div>
    </>
  );
};
