import { Logo } from "../components/Logo";

export const Presentation = () => {
  return (
    <div
      className={"flex flex-col justify-center text-center lg:px-44 lg:py-16"}
    >
      <Logo />
      <div className={"flex flex-col gap-4 pt-5"}>
        <h3 className={"text-3xl"}>
          formation professionnelle <br /> de théâtre à lyon
        </h3>
        <p className={"text-justify lg:text-left pb-8 text-xl"}>
          Ce parcours de formation s'étale sur deux années et vise à préparer
          les participants au métier de comédien.ne tout en leur ouvrant les
          portes des différentes disciplines artistiques du spectacle vivant.
          L'objectif principal de cette formation est de permettre à chaque
          individu de s'épanouir tout en favorisant la création d'un collectif
          efficace.
        </p>
        <p
          className={
            "uppercase border rounded-full py-8 px-8 w-fit text-center mx-auto text-base"
          }
        >
          En partenariat avec le <br /> Théâtre du Gai Savoir
        </p>
      </div>
    </div>
  );
};
