import lieux1 from "../assets/images/lieux1.jpg";
import lieux3 from "../assets/images/lieux3.webp";
import lieux2 from "../assets/images/lieux2.webp";

export const Lieux = () => {
  return (
    <div className={"py-16"}>
      <h1 className={"text-4xl text-center pb-8 lg:text-7xl font-lovelo"}>
        Les lieux et la formation
      </h1>
      <div className={"flex flex-col gap-10 lg:pr-96 relative text-sm"}>
        <div className={"flex flex-col lg:flex-row gap-16 items-center"}>
          <img
            src={lieux3}
            className={"h-72"}
            alt="L’entrée d’un théâtre, des canapés, des cadres sur les murs, et des plantes pendantes d’une mezzanine ou se trouvent des bureaux."
          />
          <p className={"text-justify lg:text-center text-xl leading-8"}>
            En 2020, l’école d’acteur professionnelle du Gai Savoir est
            contrainte de mettre fin à ses activités avec l'arrivée du virus
            COVID-19. Ce n'est qu'en décembre 2023 que Marina et Hypolite
            décident de la ressusciter, mais cette fois-ci avec une approche
            nouvelle et en compagnie de nouveaux visages. Ainsi, ce n'est plus
            l'école du Gai Savoir en tant que telle, mais une formation en
            partenariat avec cette institution. C’est donc en septembre, que la
            formation professionnelle de théâtre Scène Éclat ouvrira ses portes,
            accueillant une promotion inaugurale de vingt élèves.
          </p>
        </div>
        <div className={"flex flex-col lg:flex-row gap-16 items-center"}>
          <img
            src={lieux2}
            className={"w-96"}
            alt="La scène d’un théâtre, éclairée, au premier plan, des chaises et dans le fond de la scène, un rideau blanc est accroché."
          />
          <p className={"text-justify lg:text-center text-xl leading-8"}>
            La compagnie du Gai Savoir accueille dans son lieu, “Terre
            Imaginaire”, des compagnies, des créateurs, des associations et des
            spectacles vivants. Grâce à cette mouvance interdisciplinaire, les
            élèves des formations d’acteurs sont en contact régulier avec la
            dynamique d’un espace qui favorise les échanges et une plus grande
            ouverture d’esprit artistique. Le plateau, mis à la disposition des
            élèves est un site idéal à la création et à la représentation de
            spectacles
          </p>
        </div>
        <img
          className={
            "block mx-auto lg:absolute right-0 top-[60px] w-1/2 lg:w-auto lg:h-[400px]"
          }
          src={lieux1}
          alt="Photo d’une grande salle blanche avec un carrelage blanc, contre les murs se trouvent un porte manteau et des chaises."
        />
      </div>
    </div>
  );
};
