import contenu1 from "../assets/images/contenu1.jpg";
import contenu2 from "../assets/images/contenu2.jpg";
import contenu3 from "../assets/images/contenu3.jpg";
import contenu4 from "../assets/images/contenu4.jpg";
import contenu5 from "../assets/images/contenu5.jpg";
import contenu6 from "../assets/images/contenu6.png";

export const Contenu = () => {
  return (
    <div className={"flex flex-col py-16"}>
      <h1 className={"text-5xl text-center lg:text-7xl pb-8 font-lovelo"}>
        Le contenu
      </h1>
      <div
        className={
          "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-24 pt-8 text-sm pb-24"
        }
      >
        <div>
          <img
            className={"border-2 h-80 w-full object-cover"}
            src={contenu1}
            alt="Un visage, les yeux fermés, éclairé d’une fine lumière bleu clair, une main posée sur le cou lui touche les joues."
          />
          <h3 className={"text-center pt-3 pb-5 font-lovelo text-xl"}>
            Module de théâtre <br /> classique
          </h3>
          <p className={"border border-l-0 border-r-0 py-2"}>
            Durée : 6 semaines
          </p>
          <p className={"border border-t-0 border-l-0 border-r-0 py-2"}>
            Spectacle en fin de module
          </p>
        </div>
        <div>
          <img
            className={"border-2 h-80 w-full object-cover"}
            src={contenu2}
            alt="Un homme sur scène, vêtue d’une cape de pluie bleue marine, des morceaux de papiers tombent devant lui, jeté par une femme à sa gauche."
          />
          <h3 className={"text-center pt-3 pb-5 font-lovelo text-xl"}>
            Module de théâtre <br /> contemporain
          </h3>
          <p className={"border border-l-0 border-r-0 py-2"}>
            Durée : 6 semaines
          </p>
          <p className={"border border-t-0 border-l-0 border-r-0 py-2"}>
            Spectacle en fin de module
          </p>
        </div>
        <div>
          <img
            className={"border-2 h-80 w-full object-cover"}
            src={contenu3}
            alt="Un visage en gros plan dont on ne voit pas la bouche, regard vers le ciel, des traits de peintures bleus et oranges sur les joues, le nez et le front."
          />
          <h3 className={"text-center pt-3 pb-5 font-lovelo text-xl"}>
            Module de théâtre de <br /> rue
          </h3>
          <p className={"border border-l-0 border-r-0 py-2"}>
            Durée : 5 semaines
          </p>
          <p className={"border border-t-0 border-l-0 border-r-0 py-2"}>
            Spectacle en fin de module
          </p>
        </div>
        <div>
          <img
            className={"border-2 h-80 w-full object-cover"}
            src={contenu4}
            alt="Sur scène éclairés de face, une femme en robe colorée change les bandages autour de la tête d’un homme assi à une table, il est habillé d’une chemise balance et d’un pantalon noir."
          />
          <h3 className={"text-center pt-3 pb-5 font-lovelo text-xl"}>
            Improvisation
          </h3>
          <p className={"border border-l-0 border-r-0 py-2"}>
            Durée : 2 semaines non consécutives
          </p>
          <p className={"border border-t-0 border-l-0 border-r-0 py-2"}>
            Match d'impro en fin de séquence
          </p>
        </div>
        <div>
          <img
            className={"border-2 h-80 w-full object-cover"}
            src={contenu5}
            alt="Sur scène, des acteurs et actrices habillées en noir marchent sur scène, des lumières les frappent en arrière scène."
          />
          <h3 className={"text-center pt-3 pb-5 font-lovelo text-xl"}>
            Laboratoire de <br /> l'acteurice
          </h3>
          <p className={"border border-l-0 border-r-0 py-2"}>
            Durée : 4 semaines
          </p>
        </div>
        <div>
          <img
            className={"border-2 h-80 w-full object-cover"}
            src={contenu6}
            alt="Sur scène, dans une lumière violette, des acteurs et actrices en groupe baissent le haut de leur corps."
          />
          <h3 className={"text-center pt-3 pb-5 font-lovelo text-xl"}>
            Danse, chant et <br /> prosodie
          </h3>
          <p className={"border border-l-0 border-r-0 py-2"}>
            Durée : séances réparties tout au long de l’année sur certains
            mercredis
          </p>
        </div>
      </div>
    </div>
  );
};
