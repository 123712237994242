export const Navbar = ({ links }) => {
  return (
    <div
      className={
        "navbar flex justify-center text-center lg:justify-around py-4 underline flex-col lg:flex-row"
      }
    >
      {links.map((link) => (
        <a
          key={link.slug}
          title={link.slug}
          name={link.slug}
          href={`#${link.slug}`}
          className={"py-5 lg:py-0 text-lg"}
        >
          {link.title.toUpperCase()}
        </a>
      ))}
    </div>
  );
};
