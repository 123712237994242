export const Tarif = () => {
  return (
    <div className={"py-20"}>
      <h1
        className={
          "text-4xl text-center lg:text-left pb-24 lg:text-6xl font-lovelo"
        }
      >
        informations utiles
      </h1>
      <div
        className={"flex flex-col lg:grid grid-cols-1 lg:grid-cols-4 gap-16"}
      >
        <div className={"flex flex-col pr-0 lg:pr-32 gap-8 col-span-2"}>
          <p className={"text-justify lg:text-left text-xl lg:text-2xl"}>
            Cette formation professionnelle est complémentaire et compatible
            avec une activité professionnelle et/ou des études.
          </p>
          <p className={"text-justify lg:text-left text-xl lg:text-2xl"}>
            La formation se déroule du lundi au vendredi de 9h à 13h, hors
            vacances scolaires, soit 20 heures par semaine et environ 720 heures
            par an.
          </p>
          <p className={"text-justify lg:text-left text-xl lg:text-2xl"}>
            Vous serez toutefois sollicité(e) pour assister aux spectacles, aux
            matchs d’improvisation, aux événements et aux rencontres culturels
            majeurs liés à la formation, qui auront lieu certains soirs de
            semaine.
          </p>
        </div>
        <div className={"bg-dark px-10 py-10 text-sm"}>
          <h2 className={"text-center pb-8 text-4xl font-lovelo"}>Tarifs</h2>
          <div className={"flex flex-col gap-8"}>
            <p className={"text-xl"}>
              Le tarif pour suivre la formation est de 3100 euros l’année pour
              10 mois de formation intensive, soit 310 euros par mois.
            </p>
            <p className={"text-xl"}>Règlement possible en plusieurs fois.</p>
          </div>
        </div>

        <div className={"bg-dark px-10 py-10"}>
          <h2 className={"text-center pb-8 text-4xl font-lovelo"}>Contacts</h2>
          <div className={"flex flex-col gap-8 text-base"}>
            <div className={"text-center lg:text-left"}>
              <h3 className={"text-xl font-lovelo"}>Adresse</h3>
              <a
                href={
                  "https://www.google.com/maps/place/Th%C3%A9%C3%A2tre+du+Gai+Savoir/@45.7658627,4.8669943,17z/data=!3m1!4b1!4m6!3m5!1s0x47f4ea86cbfd6b2b:0x607009e224ab0bd6!8m2!3d45.7658627!4d4.8669943!16s%2Fg%2F1w345zc0?entry=ttu"
                }
              >
                <p>94 rue des Charmettes</p>
                <p>69006 LYON</p>
              </a>
            </div>
            <div className={"text-center lg:text-left"}>
              <h3 className={"text-xl font-lovelo"}>Téléphone</h3>
              <a href="tel:0769551944">
                <p>07.69.55.19.44</p>
              </a>
            </div>
            <div className={"text-center lg:text-left"}>
              <h3 className={"text-xl font-lovelo"}>E-mail</h3>
              <a href="mailto:sceneeclat@gmail.com">
                <p>sceneeclat@gmail.com</p>
              </a>
            </div>
            <div className={"text-center lg:text-left"}>
              <h3 className={"text-xl font-lovelo"}>Instagram</h3>
              <a href="https://www.instagram.com/scene_eclat?igsh=OG45eXNvdGRrYm9h">
                <p>scene_eclat</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
