import formation from "../assets/images/formation.png";

export const Formation = () => {
  return (
    <div className={"flex py-16 pb-40 flex-col lg:flex-row"}>
      <div className={"flex flex-col text-center"}>
        <div className={"flex flex-col py-5"}>
          <h1 className={"text-5xl lg:text-7xl font-lovelo"}>
            la
            <br />
            formation
          </h1>
          <p className={"text-[13px] lg:text-lg pt-2"}>
            Une formation professionnelle d’acteurs sur deux années <br /> afin
            d’approfondir et renforcer ses bases.
          </p>
        </div>
        <div className={"text-left text-justify flex flex-col gap-5 text-lg"}>
          <p>
            Notre formation professionnelle de théâtre sur deux ans offre une
            expérience complète pour celles et ceux qui souhaitent se lancer
            dans le monde exigeant et passionnant du théâtre. La première année
            est consacrée à l'établissement de bases solides et indispensables
            pour tout comédien. Les étudiants exploreront les techniques
            fondamentales du jeu d'acteur, notamment l'improvisation, la voix,
            le mouvement et l'analyse de texte. Des ateliers pratiques et des
            exercices intensifs permettront aux participants de développer leur
            présence sur scène et d'explorer différentes méthodes de création du
            personnage.
          </p>
          <p>
            La deuxième année est axée sur le développement et la consolidation
            des compétences acquises. Les étudiants auront l'opportunité de se
            plonger dans des projets plus complexes, tels que des productions
            théâtrales complètes, des performances en direct et des
            collaborations artistiques. Des cours avancés aborderont des sujets
            tels que la direction d'acteurs, la dramaturgie et la production
            théâtrale, offrant aux participants une perspective globale sur
            l'industrie du théâtre.
          </p>
          <p>
            Tout au long du programme, nos étudiants bénéficieront également
            d’un suivi pédagogique individuel et de rencontres avec des
            professionnels établis du secteur. Notre objectif est de fournir à
            nos élèves les compétences techniques, la confiance en soi et les
            connaissances nécessaires pour réussir dans leur carrière d'acteur.
          </p>
        </div>
      </div>

      <div
        className={"w-full flex px-8 justify-center items-center pt-16 lg:pt-0"}
      >
        <img
          className={"h-96 lg:h-full object-cover"}
          src={formation}
          alt={
            "Un homme pied nu accroupi, les mains au sol, la tête baissée dans une salle de\n" +
            "spectacle."
          }
        />
      </div>
    </div>
  );
};
