export const Inscription = () => {
  return (
    <div className={"py-20 pb-48"}>
      <h1 className={"text-4xl text-center lg:text-7xl pb-8 font-lovelo"}>
        inscriptions
      </h1>
      <div className={"grid grid-cols-1 lg:grid-cols-2 gap-8 text-base"}>
        <div className={"lg:pr-32"}>
          <h6 className={"underline py-8 text-xl"}>Prérequis</h6>
          <p>
            Etre âgé de 18 ans minimum. Etre titulaire du Baccalauréat. Avoir
            suivi une pratique artistique.
          </p>
          <h6 className={"underline pb-8 pt-[70px] text-xl"}>
            Dossier de candidature
          </h6>
          <p>L’inscription préalable se fait par mail à sceneeclat@gmail.com</p>
          <ul className={"list-disc py-5 pl-10"}>
            <li>Un CV</li>
            <li>Une lettre de présentation</li>
            <li>Une photo (optionnel)</li>
          </ul>
        </div>
        <div>
          <h6 className={"underline py-8 text-xl"}>Audition</h6>
          <p className={"text-justify"}>
            Une date d'audition vous sera communiquée ultérieurement. Vous
            devrez nous présenter un monologue ou une scène dialoguée tiré(e) du
            répertoire théâtral. À la suite de cette présentation, un court
            entretien sera organisé pour préciser vos attentes et pour mieux
            vous connaître.
          </p>
          <h6 className={"underline py-8 text-xl"}>Admission</h6>
          <p className={"text-justify"}>
            Suite à cet entretien, si votre candidature est retenue, un contrat
            d’engagement vous sera envoyé. Votre admission ne deviendra
            effective qu’après réception de votre contrat signé et de vos
            règlements anticipés par chèque
          </p>
        </div>
      </div>
    </div>
  );
};
